@font-face {
  font-family: ling-one;
  src: url("data:font/woff2;base64,AAEAAAAKAIAAAwAgT1MvMhG03xcAAAEoAAAAYGNtYXAADABzAAABkAAAADRnbHlmJAM2GwAAAcwAAAAYaGVhZCLbfFwAAACsAAAANmhoZWEnEvkrAAAA5AAAACRobXR4AAEAAAAAAYgAAAAIbG9jYQAMABgAAAHEAAAACG1heHAAcQAiAAABCAAAACBuYW1lBbsdPAAAAeQAAACKcG9zdHQrY6cAAAJwAAAALQABAAAAAQAAsWqD8F8PPPUAAycQAAAAAOHcugAAAAAA4edeTwAAAAAAAQABAAAABwACAAAAAAAAAAEAACcQ+DAAAAB9AAAAfAABAAEAAAAAAAAAAAAAAAAAAAABAAEAAAADAAMAAQAAAAAAAQACAB4ABgAAAGQAAAAAAAAAAwB9AZAABQAIGWQXcAAAAu4ZZBdwAAANrAAyC7gAAAAABQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAc3N6bQBAACAAIB9A+DAH0CcQB9AAAAABAAAAABOIG1gAAAAgAAAAAQAAAAAAAAAAAAIAAAADAAAAFAADAAEAAAAUAAQAIAAAAAQABAABAAAAIP//AAAAIP///+EAAQAAAAAAAAAMAAwADAABAAAAAAABAAEAAgAANRcjAQEBAQAAAAAAAAYATgADAAEECQABAAgAAAADAAEECQACAAIACAADAAEECQADAB4ACgADAAEECQAEAAwAKAADAAEECQAFAAgANAADAAEECQAGAAwAKABMAGkAbgBnADEAMQAuADAAOwBzAHMAegBtADsATABpAG4AZwAtADEATABpAG4AZwAtADEAdgAxAC4AMAAAAAIAAAAAAAD/tQAyAAAAAAAAAAAAAAAAAAAAAAAAAAAAAwAAAAMBAgRuYnNwAAAA") format("woff2");
}

.typeset wbr {
  visibility: hidden;
}

.typeset-word-break {
  word-break: keep-all;
  overflow-wrap: anywhere;
}

.typeset-thin-space, .typeset-kerning {
  letter-spacing: 0;
  -webkit-user-select: none;
  user-select: none;
}

.typeset-thin-space[data-content]:after, .typeset-kerning[data-content]:after {
  content: attr(data-content);
  font-family: ling-one;
  line-height: 0;
}

.typeset-no-breaks {
  letter-spacing: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  margin: 4.2rem 1.6rem;
}

@media (min-width: 500px) {
  body {
    margin: 6rem 3rem;
  }
}

p {
  font-feature-settings: "palt";
  letter-spacing: .13em;
  color: #09090b;
  max-width: 680px;
  margin: 0 auto;
  font-family: aktiv-grotesk, a-otf-gothic-bbb-pr6n, BlinkMacSystemFont, -apple-system, -system-ui, sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 2.2;
}

@media (min-width: 500px) {
  p {
    font-size: 1.4rem;
  }
}

.large {
  letter-spacing: .16em;
  font-size: 1.4rem;
}

@media (min-width: 500px) {
  .large {
    font-size: 1.8rem;
  }
}

.typeset {
  -webkit-text-stroke: .01em transparent;
}

.typeset-latin {
  vertical-align: .02em;
  letter-spacing: .06em;
  -webkit-text-stroke: .014em #09090b;
  font-size: 104%;
  font-weight: 200;
  line-height: 1.88308;
}

.en-section {
  line-height: 2;
}

.en-section .typeset-latin {
  line-height: 1.86476;
}

a {
  color: #09090b;
  text-underline-offset: .25em;
  text-decoration: underline;
  text-decoration-thickness: .04em;
}

a:hover {
  text-decoration: none;
}

/*# sourceMappingURL=index.d6c608d0.css.map */
