body {
  -webkit-font-smoothing: antialiased;
  margin: 4.2rem 1.6rem;
}

@media (min-width: 500px) {
  body {
    margin: 6rem 3rem;
  }
}

p {
  font-family: aktiv-grotesk, a-otf-gothic-bbb-pr6n, BlinkMacSystemFont,
    -apple-system, -system-ui, sans-serif;
  font-feature-settings: "palt";
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 2.2;
  letter-spacing: 0.13em;
  color: rgb(9 9 11);
  max-width: 680px;
  margin: 0 auto;
}

@media (min-width: 500px) {
  p {
    font-size: 1.4rem;
  }
}

.large {
  font-size: 1.4rem;
  letter-spacing: 0.16em;
}

@media (min-width: 500px) {
  .large {
    font-size: 1.8rem;
  }
}

/**
 * 共通のスタイリング
 */
.typeset {
  /**
   * Safari のフォントレンダリング対策。
   * 英数（.typeset-latin）で -webkit-text-stroke を使う場合は必須。
   * text-stroke-weight > 0, text-stroke-color: transparent
   */
  -webkit-text-stroke: 0.01em transparent;
}

/*
 * 英数のみのスタイリング
 */
.typeset-latin {
  /* フォントの拡大・縮小 */
  font-size: 104%;

  /* ベースラインの調整 */
  vertical-align: 0.02em;

  /**
   * 行間の調整。親要素 .typeset の行間と視覚的に合わせます。
   * [.typeset の line-height] ÷ [フォントの拡大率] - [ベースラインの調整値の絶対値] × 2 
   */
  line-height: calc(2 / 1.04 - 0.02 * 2);

  /* 文字間の調整 */
  letter-spacing: 0.06em;

  /* 文字の細らせ・太らせ */
  -webkit-text-stroke: 0.014em rgb(9 9 11);

  /**
   * フォールバックフォントのウェイト：
   * Web フォント読み込み時のチラつきを低減するための設定
   * -webkit-text-stroke の値に応じて変更
   */
  font-weight: 200;
}

.en-section {
  line-height: 2;
}

.en-section .typeset-latin {
  /**
   * 行間の調整。親要素 .typeset の行間と視覚的に合わせます。
   * [.typeset の line-height] ÷ [フォントの拡大率] - [ベースラインの調整値の絶対値] × 2 
   */
  line-height: calc(2 / 1.05 - 0.02 * 2);
}

a {
  color: rgb(9 9 11);
  text-decoration: underline;
  text-decoration-thickness: 0.04em;
  text-underline-offset: 0.25em;
}

a:hover {
  text-decoration: none;
}
